import clsx from "clsx";
import {
    Heading,
    HeadingMargin,
    HeadingProps,
    HeadingVariant,
    Span,
    TextVariant,
} from "components/Text";
import {
    TextField,
    TextFieldFontSize,
    TextFieldHeight,
    TextFieldProps,
    TextFieldWidth,
} from "components/TextInput/TextField";
import * as CSS from "csstype";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, {
    ChangeEvent,
    Dispatch,
    FocusEvent,
    forwardRef,
    KeyboardEvent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { FFC } from "util/type";
import "./Renameable.scss";
import { Memo } from "hooks/useBranded";

export interface RenameableProps
    extends Omit<
        TextFieldProps,
        | "autoFocus"
        | "className"
        | "disabled"
        | "ellipsify"
        | "hideLabel"
        | "leftIcon"
        | "rightButtons"
        | "width"
        | "wrapperRef"
    > {
    /**
     * If true, blur the text field when Enter is pressed. Default true.
     */
    allowEnter?: boolean;
    /**
     * The classname for the div that wraps the TextField and the (optional) {@link altComponent}.
     */
    className?: string;
    /**
     * Whether the display element can be clicked to toggle editing. Should only be set to false
     * if editing is toggled through some external means (e.g. a button).
     *
     * Defaults to true.
     */
    clickToEdit?: boolean;
    /**
     * Whether to wrap the input value in the {@link altComponent}. If no altComponent is provided,
     * has no effect.
     */
    editing: boolean;
    /**
     * If true, will ellipsify the text if it extends beyond the width of the input box.
     * Defaults to true.
     */
    ellipsify?: boolean;
    /**
     * An optional component which will render the text of the renameable, e.g. a heading.If a
     * render function is provided, it is up to the user to ensure that the styles between the
     * render and the TextField match.
     *
     * Defaults to a <Span> defined within this component.
     */
    display?: ReactNode;
    /**
     * An optional callback to run when the Renameable is renamed. This is useful because a blur
     * event isn't necessarily always a rename, e.g. when the user types "escape" the text input
     * will blur and revert the value to a previous value. For that reason, an action that
     */
    onRename?: Memo<(e: FocusEvent<HTMLInputElement>) => void>;
    /**
     * The setter for the {@link editing} prop.
     */
    setEditing: Dispatch<boolean>;
    /**
     * The setter for the value of the Renameable.
     */
    setValue: Dispatch<string>;
    /**
     * If true, the text input will be shifted to the left so that the text will be left aligned
     * with other elements rather than the left edge of the text input.
     * Defaults to true.
     */
    textAlign?: boolean;
    /**
     * The variant of text to use for both the display component and the text field.
     * Defaults to {@link TextVariant.DEFAULT}.
     */
    variant?: TextVariant;
    /**
     * The width of the Renameable. Defaults to {@link TextFieldWidth.FULL}.
     */
    width?: TextFieldWidth | CSS.Property.Width;
}

type RenameableFC = FFC<HTMLInputElement, RenameableProps> & {
    Heading: FFC<HTMLInputElement, RenameableHeadingProps>;
};

interface RenameableStyle extends CSS.Properties {
    "--bb-renameable-input-width": CSS.Property.Width;
    "--bb-renameable-wrapper-width": CSS.Property.Width;
    "--bb-renameable-input-top-diff": CSS.Property.Top;
}

/**
 * A component which looks and behaves like a standard textual components, e.g. a heading or a span,
 * but allows the user to edit it.
 *
 * This component overlays a "display" component over the text input and uses CSS to mask parts of
 * one or the other depending on whether the user is editing. The decision to do so was borne out of
 * a need to have better accessibility. Having them both in the DOM allows the display component to
 * retain its semantics and accessibility features, e.g. headings having a heading role, while also
 * allowing for text inputs to be apparent to screen readers. So while it may be a bit non-standard
 * to have two components visually on top of each other, it makes the accessibility much more
 * straightforward.
 */
export const Renameable: RenameableFC = forwardRef<HTMLInputElement, RenameableProps>(
    (
        {
            allowEnter = true,
            className,
            clickToEdit = true,
            display,
            editing,
            ellipsify = true,
            fontSize,
            height = TextFieldHeight.LARGE,
            onBlur: externalOnBlur,
            onChange: externalOnChange,
            onKeyDown: externalOnKeyDown,
            onRename,
            setEditing,
            setValue,
            textAlign = true,
            variant,
            width = TextFieldWidth.FULL,
            ...props
        },
        ref,
    ) => {
        const [prevValue, setPrevValue] = useState(props.value || "");

        // If a display node is not supplied, calculate the styles for the default display, <Span>.
        if (!display) {
            if (variant === TextVariant.SMALL) {
                height = TextFieldHeight.SMALL;
            }

            if (!fontSize) {
                fontSize =
                    variant === TextVariant.SMALL
                        ? TextFieldFontSize.SMALL
                        : variant === TextVariant.SEMIBOLD
                          ? TextFieldFontSize.MEDIUM_SEMIBOLD
                          : TextFieldFontSize.MEDIUM;
            }
            display = (
                <Span
                    className={clsx("bb-ellipsis-overflow bb-renameable__display")}
                    variant={variant}
                >
                    {props.value}
                </Span>
            );
        }

        const [inputResizeRef, inputResizeEntry] = useResizeObserver<HTMLElement>();
        const inputWidth = inputResizeEntry.target?.getBoundingClientRect().width + "px";

        const internalRef = useRef<HTMLInputElement>(null);
        const inputRef = useCombinedRef<HTMLInputElement>(internalRef, ref, inputResizeRef);
        const wrapperRef = useRef<HTMLDivElement>(null);
        // Variable to determine if the user has pressed the escape key and hence, we should not run
        // any onRename callback when we blur.
        const escapePressed = useRef(false);

        const onKeyUp = useCallback(
            (event: KeyboardEvent<HTMLInputElement>) => {
                if (allowEnter && event.key === "Enter") {
                    internalRef.current?.blur();
                    // stop propagation particularly so things like forms won't be submitted
                    event.stopPropagation();
                }
                if (event.key === "Escape") {
                    setValue(prevValue);
                    escapePressed.current = true;
                    internalRef.current?.blur();
                    // stop propagation so other event listeners, like useDetectClickOutside won't fire
                    event.stopPropagation();
                }
                externalOnKeyDown?.(event);
            },
            [allowEnter, externalOnKeyDown, prevValue, setValue],
        );

        const onBlur = useCallback(
            (event: FocusEvent<HTMLInputElement>) => {
                setEditing(false);
                externalOnBlur?.(event);
                !escapePressed.current && onRename && onRename(event);
                escapePressed.current = false;
            },
            [externalOnBlur, onRename, setEditing],
        );

        const onChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                externalOnChange?.(event);
                setValue(event.target.value);
            },
            [setValue, externalOnChange],
        );

        useEffect(() => {
            if (editing) {
                // Only set prev value if not already present
                setPrevValue((p) => (p ? p : props.value || ""));
            } else {
                setPrevValue("");
            }
        }, [editing, props.value]);

        // To ensure we always place the display over the text in the text input, we get the position
        // of the input relative to the wrapper div. We will use this top position to place the display
        // via CSS variables.
        const topDiff =
            internalRef.current && wrapperRef.current
                ? internalRef?.current?.getBoundingClientRect().top
                  - wrapperRef?.current?.getBoundingClientRect().top
                : 0;

        const style: RenameableStyle = {
            "--bb-renameable-input-width": inputWidth,
            // If the renameable width is TextFieldWidth.FLEXIBLE (which has a value of ""), the css
            // variable will not be set to the default value of "100%" as defined in the scss file.
            "--bb-renameable-wrapper-width": width,
            "--bb-renameable-input-top-diff": topDiff + "px",
        };

        return (
            <div
                className={clsx(className, "bb-renameable", `bb-renameable--${height}-height`, {
                    "bb-renameable--editing": editing,
                    "bb-renameable--clickable": clickToEdit,
                    "bb-renameable--text-aligned": textAlign,
                    "bb-renameable--full-width": width === TextFieldWidth.FULL,
                })}
                style={style}
                ref={wrapperRef}
            >
                {display}
                <TextField
                    {...props}
                    disabled={!clickToEdit && !editing}
                    ellipsify={ellipsify}
                    fontSize={fontSize}
                    height={height}
                    hideLabel={true}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={() => setEditing(true)}
                    onKeyUp={onKeyUp}
                    width={
                        width === TextFieldWidth.FLEXIBLE
                            ? TextFieldWidth.FLEXIBLE
                            : TextFieldWidth.FULL
                    }
                    ref={inputRef}
                />
            </div>
        );
    },
) as RenameableFC;

Renameable.displayName = "Renameable";

const HEADING_VARIANT_STYLES: Partial<
    Record<HeadingVariant, { textFieldFontSize: TextFieldFontSize }>
> = {
    [HeadingVariant.SMALL]: {
        textFieldFontSize: TextFieldFontSize.LARGE,
    },
    [HeadingVariant.MEDIUM]: {
        textFieldFontSize: TextFieldFontSize.EXTRA_LARGE,
    },
    [HeadingVariant.LARGE]: {
        textFieldFontSize: TextFieldFontSize.HEADING_LARGE,
    },
};

function getFontSize(v: HeadingVariant) {
    const { textFieldFontSize } = HEADING_VARIANT_STYLES[v] || {
        textFieldFontSize: TextFieldFontSize.LARGE,
    };
    return textFieldFontSize;
}

export type RenameableHeadingProps = Pick<HeadingProps, "variant" | "element" | "marginType"> &
    Omit<RenameableProps, "render" | "height" | "fontSize" | "variant">;

/**
 * A {@link Renameable} that uses a heading element as the {@link altComponent}.
 *
 * This subcomponent takes care of several things, like the layout, but also accessibility such as
 * creating the proper roles for the elements so they are keyboard interactive.
 */
Renameable.Heading = forwardRef<HTMLInputElement, RenameableHeadingProps>(
    (
        {
            className,
            element,
            marginType = HeadingMargin.DEFAULT,
            variant = HeadingVariant.SMALL,
            ...props
        },
        ref,
    ) => {
        const display = (
            <Heading
                className={"bb-ellipsis-overflow bb-renameable__display"}
                element={element}
                marginType={HeadingMargin.NONE}
                variant={variant}
            >
                {props.value}
            </Heading>
        );
        const fontSize = getFontSize(variant);

        return (
            <Renameable
                {...props}
                className={clsx(
                    className,
                    `bb-renameable--heading-${variant}`,
                    `bb-renameable--heading-margin-${marginType}`,
                )}
                fontSize={fontSize}
                height={TextFieldHeight.LARGE}
                display={display}
                ref={ref}
            />
        );
    },
);
Renameable.Heading.displayName = "Renameable.Heading";
